import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import Form from '../../components/Form';
import ValidationErrors from '../../components/ValidationErrors';
import FormGroup from '../../components/FormFields/FormGroup';
import TextInput from '../../components/FormFields/TextInput';
import CheckboxInput from '../../components/FormFields/CheckboxInput';
import AppointmentTemplateTimeSlots from './AppointmentTemplateTimeSlots';
import Button from '../../components/Button';
import TagsInput from '../../components/FormFields/TagsInput';
import { loadingStates } from '../../hooks/useApi';
import { apiLoadingFamily } from '../../store/atoms/api';
import AppointmentTemplateDeleteConfirm from './AppointmentTemplateDeleteConfirm';
import { translationsAtom } from '../../store/atoms/i18n';

export default function AppointmentTemplateForm({
  schema,
  schemaCyclicDependencies,
  onSubmit,
  validationErrors,
  formRef,
  showDelete,
  initialData,
}) {
  const t = useRecoilValue(translationsAtom);
  const appointmentTemplateDetailLoadingState = useRecoilValue(apiLoadingFamily('appointment-template'));
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const onSubmitProxy = useCallback((submitData) => {
    onSubmit(submitData);
  }, [onSubmit]);

  const onOpenDeleteConfirmation = useCallback(() => {
    setDeleteConfirmOpen(true);
  }, []);

  const onCloseDeleteConfirmation = useCallback(() => {
    setDeleteConfirmOpen(false);
  }, []);

  return (
    <Form
      schemaObject={schema}
      schemaCyclicDependencies={schemaCyclicDependencies}
      onSubmit={onSubmitProxy}
      ref={formRef}
      defaultValues={initialData}
    >
      <ValidationErrors errors={validationErrors} />

      <div className="bg-gray-100 p-4 md:p-6 -mx-4 md:-mx-6 rounded-xl mb-6">
        <FormGroup>
          <CheckboxInput label={t.appointment_template.delivery_availability} id="is_available" schema={schema.is_available} value="1" />
        </FormGroup>

        <FormGroup>
          <TextInput label={t.appointment_template.template_title} id="title" schema={schema.title} />
        </FormGroup>

        <FormGroup>
          <TextInput label={t.appointment_template.max_tasks} id="max_tasks" inputType="number" schema={schema.max_tasks} />
        </FormGroup>

        <FormGroup>
          <TagsInput
            label={t.appointment_template.tags}
            id="tags"
          />
          {' '}
        </FormGroup>
      </div>

      <div className="bg-gray-100 p-4 md:p-6 -mx-4 md:-mx-6 rounded-xl mb-6">
        <h2 className="text-xl font-bold mb-6">
          {t.appointment_template.timeslots}
        </h2>

        <AppointmentTemplateTimeSlots />
      </div>
      <div className="flex items-center mt-6 md:mt-10">
        <Button
          buttonType="submit"
          styleType="primary"
          className="mr-auto flex items-center"
          disabled={appointmentTemplateDetailLoadingState === loadingStates.LOADING}
        >
          {
            appointmentTemplateDetailLoadingState === loadingStates.LOADING
              ? t.appointment_template.saving
              : t.appointment_template.save
          }
        </Button>
        {
          showDelete && !initialData.default_template
            ? (
              <>
                <button
                  type="button"
                  className="pb-1 border-b border-red-400 text-red-600 hover:text-red-800 hover:border-red-700"
                  onClick={onOpenDeleteConfirmation}
                >
                  {t.appointment_template.delete}
                </button>

                <AppointmentTemplateDeleteConfirm
                  isOpen={deleteConfirmOpen}
                  appointmentTemplateId={initialData.id}
                  onClose={onCloseDeleteConfirmation}
                  redirectToOverview
                />
              </>
            )
            : null
        }
      </div>
    </Form>
  );
}

AppointmentTemplateForm.defaultProps = {
  validationErrors: null,
  showDelete: false,
};

AppointmentTemplateForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schemaCyclicDependencies: PropTypes.arrayOf(PropTypes.array).isRequired,
  validationErrors: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/no-unused-prop-types
  loadingState: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialData: PropTypes.object.isRequired,
  showDelete: PropTypes.bool,
  formRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.shape({
        resetForm: PropTypes.func,
      }),
    }),
  ]).isRequired,
};
