import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';
import { useRecoilValue } from 'recoil';
import { translationsAtom } from '../../store/atoms/i18n';

export default function TablePagination({
  className,
  collection,
  onPageChange,
  onPageLengthChange,
  pageLengths,
  disabled,
}) {
  const t = useRecoilValue(translationsAtom);
  const currentPage = collection?.current_page;

  const isPageAvailable = useCallback((pageNumber) => {
    return pageNumber >= 1 && pageNumber <= collection?.last_page;
  }, [collection]);

  const pageNumbersToShow = useMemo(() => {
    if (!collection || (collection.total <= collection.per_page)) {
      return [];
    }

    const pageNumbers = [
      1,
      2,
      collection.current_page - 1,
      collection.current_page,
      collection.current_page + 1,
      collection.last_page - 1,
      collection.last_page,
    ]
      .filter((pageNumber) => {
        return isPageAvailable(pageNumber);
      });

    // Add '...' between page numbers that skip numbers.
    // e.g. 1 2 ... 4 5
    pageNumbers.forEach((pageNumber, index) => {
      if (index > 0) {
        const previousPageNumber = pageNumbers[index - 1];
        const diffInPageNumber = pageNumber - previousPageNumber;

        if (diffInPageNumber > 1) {
          pageNumbers.splice(index, 0, '...');
        }
      }
    }, []);

    return sortBy(uniq(pageNumbers));
  }, [collection, isPageAvailable]);

  const pageNumberElements = useMemo(() => {
    return pageNumbersToShow.map((pageNumber) => {
      if (typeof pageNumber === 'string') {
        return (
          <span key={pageNumber} className="select-none text-gray-400 leading-none -mt-2 px-3">
            ...
          </span>
        );
      }

      return (
        <button
          className={`${currentPage === pageNumber ? 'bg-primary text-white font-bold' : 'bg-white hover:bg-gray-100 focus:bg-gray-200'} block w-12 h-12 justify-center items-center rounded-full border ml-1`}
          type="button"
          key={pageNumber}
          onClick={() => onPageChange(pageNumber)}
          disabled={disabled}
        >
          {pageNumber}
        </button>
      );
    });
  }, [pageNumbersToShow, currentPage, disabled, onPageChange]);

  if (!collection) {
    return null;
  }

  return (
    <div className={`${className} flex p-3 bg-white border-t`}>
      {onPageLengthChange && (
        <div className="flex items-center ml-3">
          <select
            className="mr-3"
            aria-label={t.pagination.results_per_page_label}
            onChange={(event) => onPageLengthChange(event.currentTarget.value)}
          >
            {pageLengths.map((pageLength) => {
              return (
                <option key={pageLength} value={pageLength}>
                  {pageLength}
                </option>
              );
            })}
          </select>
          {t.pagination.results_per_page}
        </div>
      )}

      <div className="ml-auto flex items-center">
        { isPageAvailable(currentPage - 1) && (
          <button
            className="bg-white hover:bg-gray-100 focus:bg-gray-200 block px-6 h-12 justify-center items-center rounded-full border ml-1"
            type="button"
            onClick={() => onPageChange(currentPage - 1)}
            disabled={disabled}
          >
            {t.pagination.previous}
          </button>
        )}

        {pageNumberElements}

        { isPageAvailable(currentPage + 1) && (
          <button
            className="bg-white hover:bg-gray-100 focus:bg-gray-200 block px-6 h-12 justify-center items-center rounded-full border ml-1"
            type="button"
            onClick={() => onPageChange(currentPage + 1)}
            disabled={disabled}
          >
            {t.pagination.next}
          </button>
        )}
      </div>
    </div>
  );
}

TablePagination.defaultProps = {
  disabled: false,
  collection: null,
  className: '',
  onPageLengthChange: null,
};

TablePagination.propTypes = {
  collection: PropTypes.shape({
    total: PropTypes.number,
    current_page: PropTypes.number,
    per_page: PropTypes.number,
    last_page: PropTypes.number,
    /* eslint-disable react/forbid-prop-types */
    data: PropTypes.arrayOf(PropTypes.object),
  }),
  onPageChange: PropTypes.func.isRequired,
  onPageLengthChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  pageLengths: PropTypes.arrayOf(PropTypes.number).isRequired,
};
