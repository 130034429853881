import { useSetRecoilState } from 'recoil';
import useApi from '../../hooks/useApi';
import { apiLoadingFamily } from '../atoms/api';
import useToastActions from './toasts';
import { appointmentTemplateDaysAtom, appointmentTemplateWeekDaysAtom } from '../atoms/appointmentTemplateDays';

export default function useAppointmentTemplateDaysActions() {
  const setAppointmentTemplateDays = useSetRecoilState(appointmentTemplateDaysAtom);
  const setAppointmentTemplateWeekDays = useSetRecoilState(appointmentTemplateWeekDaysAtom);
  const setAppointmentTemplateDaysState = useSetRecoilState(
    apiLoadingFamily('appointment-template-days'),
  );

  const toastActions = useToastActions();

  const { handle: AppointmentTemplateDaysApiHandle } = useApi({
    onLoadingStateChange: (loadingState) => setAppointmentTemplateDaysState(loadingState),
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  async function fetchAll() {
    const results = await AppointmentTemplateDaysApiHandle('get', 'appointment-template-days');

    if (results) {
      setAppointmentTemplateDays(results);
    }

    return results;
  }

  async function fetchAllWeekDays() {
    const results = await AppointmentTemplateDaysApiHandle('get', 'appointment-template-weekdays');

    if (results) {
      setAppointmentTemplateWeekDays(results);
    }

    return results;
  }

  async function store(templateId, date, setCurrentTemplateDay) {
    const result = await AppointmentTemplateDaysApiHandle('post', 'appointment-template-days', {
      start: date,
      appointment_template_id: templateId,
    });

    if (result) {
      setCurrentTemplateDay(result);
    }

    return result;
  }

  async function destroy(date, setCurrentTemplateDay) {
    const result = await AppointmentTemplateDaysApiHandle('delete', 'appointment-template-days', { start: date });

    if (result) {
      setCurrentTemplateDay(null);
    }

    return result;
  }

  async function storeWeekDay(weekday, templateId) {
    const result = await AppointmentTemplateDaysApiHandle('post', 'appointment-template-weekdays', {
      weekday,
      appointment_template_id: templateId,
    });

    if (result) {
      setAppointmentTemplateWeekDays(result);
    }

    return result;
  }

  return {
    fetchAll,
    fetchAllWeekDays,
    store,
    storeWeekDay,
    destroy,
  };
}
