import * as yup from 'yup';

export default function useAppointmentTemplateSchema() {
  const schema = {
    title: yup
      .string()
      .min(0)
      .max(50)
      .required(),
    is_available: yup
      .bool(),
    max_tasks: yup
      .number()
      .notRequired()
      .optional()
      .transform((value) => {
        return Number.isNaN(value) ? undefined : value;
      })
      .when('max_tasks', {
        is: (val) => val,
        then: yup.number().min(0).max(2000),
      }),
  };

  const cyclicDependencies = [
    ['max_tasks', 'max_tasks'],
  ];

  return [
    schema,
    cyclicDependencies,
  ];
}
