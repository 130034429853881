import React from 'react';
import PropTypes from 'prop-types';

export default function CardBody({ className, children }) {
  return (
    <div className={`p-6 md:p-8 ${className}`}>
      {children}
    </div>
  );
}

CardBody.defaultProps = {
  className: '',
};

CardBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
