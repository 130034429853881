import React from 'react';
import PropTypes from 'prop-types';

export default function OverviewHead({ title, actions, filters }) {
  let filtersWrapperElement = null;

  if (filters) {
    filtersWrapperElement = (
      <div className="flex items-center ml-auto md:ml-6 mt-6 sm:mt-0">
        {filters}
      </div>
    );
  }

  return (
    <header className="lg:flex lg:items-center mb-6 md:mb-10">
      <div className="sm:flex sm:items-center mb-6 lg:mb-0">
        <h1 className="font-bold text-xl md:text-2xl whitespace-nowrap">
          {title}
        </h1>

        {filtersWrapperElement}
      </div>

      <div className="md:ml-auto flex items-center">
        {actions}
      </div>
    </header>
  );
}

OverviewHead.defaultProps = {
  filters: null,
};

OverviewHead.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  filters: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
