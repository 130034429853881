import React, { useCallback, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Container from '../../components/Container';
import Main from '../../components/Main';
import MetaHead from '../../components/MetaHead';
import SvgIcon from '../../components/SvgIcon';
import AppointmentTemplateForm from './AppointmentTemplateForm';
import Card from '../../components/Card';
import useAppointmentTemplateSchema from '../../schemas/appointmentTemplateSchema';
import { apiErrorsFamily, apiLoadingFamily } from '../../store/atoms/api';
import CardBody from '../../components/CardBody';
import CardHead from '../../components/CardHead';
import useAppointmentTemplateActions from '../../store/actions/appointmentTemplates';
import useToastActions from '../../store/actions/toasts';
import { translationsAtom } from '../../store/atoms/i18n';

export default function AppointmentTemplateCreate() {
  const t = useRecoilValue(translationsAtom);
  const formRef = useRef();
  const [schema, schemaCyclicDependencies] = useAppointmentTemplateSchema();
  const appointmentTemplateActions = useAppointmentTemplateActions();
  const appointmentTemplateDetailValidationErrors = useRecoilValue(apiErrorsFamily('appointment-template'));
  const appointmentTemplateDetailLoadingState = useRecoilValue(apiLoadingFamily('appointment-template'));
  const toastActions = useToastActions();
  const navigate = useNavigate();

  const onSubmit = useCallback(async (data) => {
    const result = await appointmentTemplateActions.store(data);

    toastActions.addGlobalToastItem(t.appointment_template.template_created);

    if (result && result.id) {
      navigate(`/appointment-template/${result.id}`);
    }
  }, [appointmentTemplateActions, toastActions, t, navigate]);

  const initialData = {
    is_available: true,
  };

  return (
    <>
      <MetaHead title={t.appointment_template.title_add} />

      <Main>
        <Container>
          <NavLink className="btn btn--empty text-gray-600 mr-4" to="/appointment-calendar">
            <SvgIcon identifier="chevronLeft" className="mr-2 text-gray-300" />
            {t.appointment_template.calendar_return}
          </NavLink>

          <Card className="my-6">
            <CardHead>
              <h1 className="text-2xl font-bold">
                {t.appointment_template.title_add}
              </h1>
            </CardHead>

            <CardBody>
              <div className="md:px-6">
                <AppointmentTemplateForm
                  formRef={formRef}
                  schema={schema}
                  schemaCyclicDependencies={schemaCyclicDependencies}
                  onSubmit={onSubmit}
                  validationErrors={appointmentTemplateDetailValidationErrors}
                  loadingState={appointmentTemplateDetailLoadingState}
                  initialData={initialData}
                />
              </div>
            </CardBody>
          </Card>
        </Container>
      </Main>
    </>
  );
}
