import React, {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useRecoilValue } from 'recoil';
import interactionPlugin from '@fullcalendar/interaction';
import nlLocale from '@fullcalendar/core/locales/nl';
import PropTypes from 'prop-types';
import { appointmentTemplatesAtom } from '../../store/atoms/appointmentTemplates';
import {
  appointmentTemplateDaysAtom,
  appointmentTemplateWeekDaysAtom,
} from '../../store/atoms/appointmentTemplateDays';
import useAppointmentTemplateActions from '../../store/actions/appointmentTemplates';
import useAppointmentTemplateDaysActions from '../../store/actions/appointmentCalendar';
import DayCellContent from './DayCellContent';
import { translationsAtom } from '../../store/atoms/i18n';
import useLocaleKey from '../../hooks/useLocaleKey';

export default function Calendar({ hidden }) {
  const t = useRecoilValue(translationsAtom);
  const appointmentTemplates = useRecoilValue(appointmentTemplatesAtom);
  const appointmentTemplateDays = useRecoilValue(appointmentTemplateDaysAtom);
  const appointmentTemplateWeekDays = useRecoilValue(appointmentTemplateWeekDaysAtom);
  const appointmentTemplatesActions = useAppointmentTemplateActions();
  const appointmentTemplateDaysActions = useAppointmentTemplateDaysActions();
  const today = new Date().setHours(0, 0, 0, 0);
  const calendarRef = useRef(null);
  const locale = useLocaleKey();
  const [calendarLocale, setCalendarLocale] = useState();
  const defaultTemplate = appointmentTemplates.find(
    (template) => template.default_template === 1,
  );
  const [weekDays, setWeekDays] = useState([
    { id: 1, name: t.appointment.calendar.weekdays.monday, appointment_template_id: null },
    { id: 2, name: t.appointment.calendar.weekdays.tuesday, appointment_template_id: null },
    { id: 3, name: t.appointment.calendar.weekdays.wednesday, appointment_template_id: null },
    { id: 4, name: t.appointment.calendar.weekdays.thursday, appointment_template_id: null },
    { id: 5, name: t.appointment.calendar.weekdays.friday, appointment_template_id: null },
    { id: 6, name: t.appointment.calendar.weekdays.saturday, appointment_template_id: null },
    { id: 7, name: t.appointment.calendar.weekdays.sunday, appointment_template_id: null },
  ]);

  useEffect(() => {
    if (locale === 'nl') {
      setCalendarLocale(nlLocale);
    }
  }, [locale]);

  useEffect(() => {
    if (!hidden && calendarRef.current) {
      // Trigger a resize when the calendar becomes visible
      calendarRef.current.getApi().updateSize();
    }
  }, [hidden]);

  useEffect(() => {
    appointmentTemplatesActions.fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    appointmentTemplateDaysActions.fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    appointmentTemplateDaysActions.fetchAllWeekDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appointmentTemplateWeekDays) {
      setWeekDays((prevWeekDays) => prevWeekDays.map((weekDay) => {
        const matchingTemplate = appointmentTemplateWeekDays.find(
          (template) => Number(template.weekday) === Number(weekDay.id),
        );

        return {
          ...weekDay,
          appointment_template_id: matchingTemplate
            ? matchingTemplate.appointment_template_id
            : null,
        };
      }));
    }
  }, [appointmentTemplateWeekDays]);

  const handleAppointmentWeekDaySelection = useCallback(async (weekday, templateId) => {
    await appointmentTemplateDaysActions.storeWeekDay(weekday, templateId);
  }, [appointmentTemplateDaysActions]);

  const dayCellDidMount = (info) => {
    // Allow dropdown interaction
    const cellEl = info.el;
    cellEl.addEventListener('mousedown', (e) => e.stopPropagation());
    cellEl.addEventListener('click', (e) => e.stopPropagation());
  };

  function getWeekDayInt(date) {
    const day = date.getDay();
    return day === 0 ? 7 : day;
  }

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.render(); // Forces a re-render of the calendar
    }
  }, [weekDays]);

  const renderDayCellContent = (info) => {
    return (
      <DayCellContent
        date={info.date}
        appointmentTemplateDay={appointmentTemplateDays.find(
          (day) => day.start === info.date.toISOString().split('T')[0],
        )}
        appointmentTemplateWeekDay={appointmentTemplateWeekDays.find(
          (day) => day.weekday === getWeekDayInt(info.date),
        )}
        appointmentTemplates={appointmentTemplates}
        appointmentTemplateDaysActions={appointmentTemplateDaysActions}
        today={today}
        defaultTemplate={defaultTemplate}
      />
    );
  };

  return (
    <div className="bg-white rounded-b-xl md:rounded-xl shadow-md p-6 md:p-8">
      <div className="grid grid-cols-7 gap-1 place-items-center mb-6">
        {weekDays.map((weekday) => (
          <div
            key={weekday.id}
            className="col-span-1 text-center flex flex-col justify-between h-full"
          >
            <div className="font-semibold py-2">
              {weekday.name}
            </div>

            <select
              className="form-control form-control--sm"
              onChange={
              (e) => handleAppointmentWeekDaySelection(weekday.id, parseInt(e.target.value, 10))
            }
              value={weekday.appointment_template_id || ''}
            >
              {appointmentTemplates.map((template) => (
                <option key={template.id} value={template.id}>
                  {template.title}
                </option>
              ))}
            </select>
          </div>
        ))}
      </div>

      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        firstDay={1}
        timeZone="UTC"
        locale={calendarLocale}
        dayCellContent={renderDayCellContent}
        dayCellDidMount={dayCellDidMount}
        ref={calendarRef}
        eventContent={false}
      />
    </div>
  );
}

Calendar.propTypes = {
  hidden: PropTypes.bool.isRequired,
};
