/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from './ErrorMessage';
import ConnectForm from './ConnectForm';
import HelpLabel from './HelpLabel';

export default function CheckboxInput({
  label,
  labelHelp,
  description,
  id,
  name,
  schema,
  value,
  className,
  dangerouslySetLabel,
}) {
  const isRequired = schema.exclusiveTests?.required || false;

  const requiredLabel = isRequired
    ? <abbr title="Required">*</abbr>
    : null;

  /* eslint-disable react/no-danger */
  const descriptionElement = description
    ? <div className="pt-1" dangerouslySetInnerHTML={{ __html: description }} />
    : null;
  /* eslint-enable react/no-danger */

  const labelElement = (
    <div className="font-bold">
      {
        dangerouslySetLabel
          // eslint-disable-next-line react/no-danger
          ? <span dangerouslySetInnerHTML={{ __html: label }} />
          : label
      }
      {requiredLabel}
      <HelpLabel text={labelHelp} />
    </div>
  );

  const fieldName = name || id;

  return (
    <ConnectForm>
      {({ register, error }) => (
        <>
          <label htmlFor={id} className={`${className} flex flex-col flex-no-wrap}`}>
            <div className="items-center flex">
              <input
                type="checkbox"
                className="mr-2 form-checkbox"
                name={fieldName}
                id={id}
                value={value}
                {...register(fieldName)}
              />
              {labelElement}
            </div>

            <div className="">
              {descriptionElement}
            </div>
          </label>

          <ErrorMessage message={error} />
        </>
      )}
    </ConnectForm>
  );
}

CheckboxInput.defaultProps = {
  schema: {},
  description: null,
  name: null,
  value: '',
  className: '',
  dangerouslySetLabel: false,
  labelHelp: null,
};

CheckboxInput.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  labelHelp: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object,
  className: PropTypes.string,
  dangerouslySetLabel: PropTypes.bool,
};
