import React, { useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import FormGroup from '../../components/FormFields/FormGroup';
import TextInput from '../../components/FormFields/TextInput';
import SelectInput from '../../components/FormFields/SelectInput';
import useAppointmentSettingsSchema from '../../schemas/appointmentSettingsSchema';
import Form from '../../components/Form';
import Button from '../../components/Button';
import { loadingStates } from '../../hooks/useApi';
import { apiErrorsFamily, apiLoadingFamily } from '../../store/atoms/api';
import { translationsAtom } from '../../store/atoms/i18n';
import PageLoader from '../../components/PageLoader';
import useAppointmentSettingsActions from '../../store/actions/appointmentSettings';
import { appointmentSettingsAtom } from '../../store/atoms/appointmentSettings';
import analytics from '../../helpers/analytics';

export default function Settings() {
  const t = useRecoilValue(translationsAtom);
  const appointmentSettingsActions = useAppointmentSettingsActions();
  const appointmentSettings = useRecoilValue(appointmentSettingsAtom);
  const appointmentSettingsLoadingState = useRecoilValue(apiLoadingFamily('appointment-settings'));
  const appointmentSettingsValidationErrors = useRecoilValue(apiErrorsFamily('appointment-settings'));
  const { schema, cyclicDependencies } = useAppointmentSettingsSchema();
  const weekdays = {
    0: t.appointment.settings.weekdays.none,
    1: t.appointment.settings.weekdays.monday,
    2: t.appointment.settings.weekdays.tuesday,
    3: t.appointment.settings.weekdays.wednesday,
    4: t.appointment.settings.weekdays.thursday,
    5: t.appointment.settings.weekdays.friday,
    6: t.appointment.settings.weekdays.saturday,
    7: t.appointment.settings.weekdays.sunday,
  };

  useEffect(() => {
    appointmentSettingsActions.fetch();
    /* eslint-disable-next-line */
  }, []);

  const initialData = appointmentSettings;

  const onSubmit = useCallback((data) => {
    appointmentSettingsActions.update(data);

    analytics.track('Settings update', {
      category: 'Settings update',
    });
  }, [appointmentSettingsActions]);

  if (Object.keys(appointmentSettings).length === 0) {
    return <PageLoader />;
  }

  return (
    <div className="bg-white rounded-b-xl md:rounded-xl shadow-md p-6 md:p-8">
      <Form
        schemaObject={schema}
        schemaCyclicDependencies={cyclicDependencies}
        onSubmit={onSubmit}
        defaultValues={initialData}
      >
        <FormGroup>
          <TextInput
            label={t.appointment.settings.minimal_delivery_days}
            id="minimal_delivery_days"
            inputType="number"
            schema={schema.minimal_delivery_days}
          />
        </FormGroup>

        <FormGroup>
          <TextInput
            label={t.appointment.settings.available_delivery_days}
            id="available_delivery_days"
            inputType="number"
            schema={schema.available_delivery_days}
          />
        </FormGroup>

        <FormGroup>
          <SelectInput
            label={t.appointment.settings.default_delivery_day}
            id="default_delivery_day"
            schema={schema.default_delivery_day}
            options={weekdays}
          />
        </FormGroup>

        <div className="flex items-center mt-6">
          <Button
            buttonType="submit"
            styleType="primary"
            className="ml-auto"
            disabled={appointmentSettingsLoadingState === loadingStates.LOADING}
          >
            {/* eslint-disable-next-line max-len */}
            {appointmentSettingsValidationErrors === loadingStates.LOADING ? t.appointment.settings.saving : t.appointment.settings.save}
          </Button>
        </div>
      </Form>
    </div>
  );
}
