import React from 'react';
import { useRecoilValue } from 'recoil';
import Spinner from '../Spinner';
import { translationsAtom } from '../../store/atoms/i18n';

export default function TableLoadingRow() {
  const t = useRecoilValue(translationsAtom);

  return (
    <div className="py-4 px-5 bg-white">
      <span className="sr-only">{t.pagination.loading}</span>
      <Spinner className="mx-auto w-10 h-10 my-5" />
    </div>
  );
}

TableLoadingRow.defaultProps = {
  //
};

TableLoadingRow.propTypes = {
  //
};
