import { useRecoilValue, useSetRecoilState } from 'recoil';
import useApi from '../../hooks/useApi';
import { apiLoadingFamily } from '../atoms/api';
import useToastActions from './toasts';
import { appointmentSettingsAtom } from '../atoms/appointmentSettings';
import { toastTypes } from '../atoms/toasts';
import { translationsAtom } from '../atoms/i18n';

export default function useAppointmentSettingsActions() {
  const t = useRecoilValue(translationsAtom);
  const setAppointmentSettings = useSetRecoilState(appointmentSettingsAtom);
  const setAppointmentSettingsState = useSetRecoilState(
    apiLoadingFamily('appointment-settings'),
  );

  const toastActions = useToastActions();

  const { handle: appointmentSettingsApiHandle } = useApi({
    onLoadingStateChange: (loadingState) => setAppointmentSettingsState(loadingState),
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  async function fetch() {
    const result = await appointmentSettingsApiHandle('get', 'appointment-settings');

    if (result) {
      setAppointmentSettings(result);
    }

    return result;
  }

  async function update(data) {
    const result = await appointmentSettingsApiHandle('put', 'appointment-settings', data);
    toastActions.addGlobalToastItem(t.settings.settings_saved, toastTypes.SUCCESS, 6000);

    return result;
  }

  return {
    update,
    fetch,
  };
}
