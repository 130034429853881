import { useRecoilValue } from 'recoil';
import React from 'react';
import PropTypes from 'prop-types';
import { translationsAtom } from '../../store/atoms/i18n';

export default function TableEmptyStateRow({ className }) {
  const t = useRecoilValue(translationsAtom);

  return (
    <div className={`${className} bg-white py-10 px-5 text-center text-base text-gray-500`}>
      {t.pagination.empty_state}
    </div>
  );
}

TableEmptyStateRow.defaultProps = {
  className: '',
};

TableEmptyStateRow.propTypes = {
  className: PropTypes.string,
};
