export const colorMap = {
  yellow: {
    className: 'bg-yellow-300/20',
  },
  red: {
    className: 'bg-red-500/10',
  },
  blue: {
    className: 'bg-blue-500/10',
  },
  green: {
    className: 'bg-green-500/10',
  },
  purple: {
    className: 'bg-purple-500/10',
  },
  gray: {
    className: 'bg-gray-500/10',
  },
  pink: {
    className: 'bg-pink-500/10',
  },
  orange: {
    className: 'bg-orange-500/10',
  },
};

export const iconMap = {
  scan: 'scanning',
  time: 'timeSlot',
  megaphone: 'megaphone',
  check: 'check',
  plus: 'plus',
  cross: 'cross',
  calendar: 'calendarLines',
};
