import React, { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import TextInput from '../../components/FormFields/TextInput';
import SvgIcon from '../../components/SvgIcon';
import Button from '../../components/Button';
import TagsInput from '../../components/FormFields/TagsInput';
import { translationsAtom } from '../../store/atoms/i18n';

export default function AppointmentTemplateTimeSlots(defaultValues) {
  const t = useRecoilValue(translationsAtom);
  const { control } = useFormContext({ defaultValues });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'time_slots',
  });

  const handleAddLine = () => {
    append(1);
  };

  const linesMemo = useMemo(() => {
    const removeTimeSlot = (index) => {
      remove(index);
    };

    return fields.map((field, index) => {
      return (
        <div className="lg:grid grid-cols-10 gap-x-6 mt-4" key={field.id}>
          <div className="h-12 col-span-2">
            <TextInput
              className="py-[23px]"
              label=""
              id={`time_slots.${index}.start_time`}
              inputType="time"
            />
          </div>

          <div className="col-span-2">
            <TextInput
              className="py-[23px]"
              label=""
              inputType="time"
              id={`time_slots.${index}.end_time`}
            />
          </div>

          <div className="col-span-5">
            <TagsInput
              label=""
              id={`time_slots.${index}.tags`}
            />
          </div>

          <button
            type="button"
            className="col-span-1 flex items-center justify-center py-8"
            onClick={() => removeTimeSlot(index)}
          >
            <SvgIcon identifier="trash" className="w-7 h-7 text-gray-400" />

            <span className="sr-only">
              remove
            </span>
          </button>
        </div>
      );
    });
  }, [fields, remove]);

  return (
    <>
      <div className="grid grid-cols-10 gap-x-6 mb-4">
        <span className="col-span-2 font-bold flex items-end">{t.appointment_template.start_time}</span>
        <span className="col-span-2 font-bold flex items-end">{t.appointment_template.end_time}</span>
        <span className="col-span-5 font-bold flex items-end">{t.appointment_template.tags}</span>
        <span className="col-span-1 font-bold flex items-end">&nbsp;</span>
      </div>

      <hr />

      {linesMemo}

      <div className="mt-5">
        {fields.length >= 5 ? (
          <p className="text-gray-500">{t.appointment_template.max_timeslots_reached}</p>
        ) : (
          <Button
            styleType="primary"
            icon="plus"
            className="mt-5"
            iconClass="mr-2 opacity-60"
            onClick={handleAddLine}
          >
            {t.appointment_template.new_timeslot}
          </Button>
        )}
      </div>
    </>
  );
}
