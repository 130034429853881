import React, {
  useEffect, useState,
} from 'react';
import { useRecoilValue } from 'recoil';
import Main from '../../components/Main';
import TabListItem from './TabListItem';
import Calendar from './Calendar';
import { translationsAtom } from '../../store/atoms/i18n';
import Container from '../../components/Container';
import Settings from './Settings';
import Templates from './Templates';

export default function Appointment() {
  const t = useRecoilValue(translationsAtom);
  const [activeTab, setActiveTab] = useState('calendar');

  useEffect(() => {
    if (window.location.hash && window.location.hash.indexOf('#tab=') === 0) {
      const tabIdentifier = window.location.hash.replace('#tab=', '');
      setActiveTab(tabIdentifier);
    }
    /* eslint-disable-next-line */
  }, []);

  return (
    <Main>
      <Container className="max-w-384">
        <div className="flex items-center justify-between mb-6 md:mb-10">
          <h1 className="font-bold text-xl md:text-2xl whitespace-nowrap mr-6">
            {t.appointment.calendar.title}
          </h1>

          <a href={t.appointment.how_does_it_work_url} className="btn btn--empty mr-2" target="_blank" rel="noreferrer">
            {t.appointment.how_does_it_work}
          </a>
        </div>

        <div className="md:grid grid-cols-8 md:w-full">
          <nav className="border-b -mb-[1px] md:border-b-0 col-span-2 xl:col-span-1">
            <ul className="flex overflow-x-auto gap-x-2 md:flex-col hide-scrollbars">
              <TabListItem
                icon="calendar"
                title={t.appointment.calendar.title}
                identifier="calendar"
                active={activeTab === 'calendar'}
                onClick={(id) => setActiveTab(id)}
              />

              <TabListItem
                icon="template"
                title={t.appointment.templates.title}
                identifier="templates"
                active={activeTab === 'templates'}
                onClick={(id) => setActiveTab(id)}
              />

              <TabListItem
                icon="cog"
                title={t.appointment.settings.title}
                identifier="settings"
                active={activeTab === 'settings'}
                onClick={(id) => setActiveTab(id)}
              />
            </ul>
          </nav>

          <div className="col-span-6 xl:col-span-7">
            <div className="mb-4 xl:mb-10" hidden={activeTab !== 'calendar'}>
              <Calendar hidden={activeTab !== 'calendar'} />
            </div>

            <div className="mb-4 xl:mb-10" hidden={activeTab !== 'templates'}>
              <Templates />
            </div>

            <div className="mb-4 xl:mb-10" hidden={activeTab !== 'settings'}>
              <Settings />
            </div>
          </div>
        </div>
      </Container>
    </Main>
  );
}
