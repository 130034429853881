import React from 'react';
import PropTypes from 'prop-types';

export default function TableHead({ children, screenReaderOnly }) {
  return (
    <thead className={`text-left border-b ${screenReaderOnly ? 'sr-only' : ''}`}>
      {children}
    </thead>
  );
}

TableHead.defaultProps = {
  screenReaderOnly: false,
};

TableHead.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  screenReaderOnly: PropTypes.bool,
};
