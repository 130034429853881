import { format, startOfDay } from 'date-fns';
import { atom } from 'recoil';

export const tasksDetailItemAtom = atom({
  key: 'tasksDetailItemAtom',
  default: null,
});

export const tasksOverviewItemsAtom = atom({
  key: 'tasksOverviewItems',
  default: {},
});

export const tasksOverviewFiltersAtom = atom({
  key: 'tasksOverviewFiltersAtom',
  default: {
    search: '',
    date: format(startOfDay(new Date()), 'yyyy-MM-dd'),
    date_to: null,
    page: 1,
    page_not_delivered: 1,
    page_task_appointments: 1,
    type: '',
    status: '',
  },
});

export const tasksNotDeliveredOverviewItemsAtom = atom({
  key: 'tasksNotDeliveredOverviewItems',
  default: {},
});
