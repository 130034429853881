import React from 'react';
import PropTypes from 'prop-types';
import { CardStyleContext } from '../Card';
import SvgIcon from '../SvgIcon';

export default function CardHead({ children, className, iconIdentifier }) {
  let icon = null;

  if (iconIdentifier) {
    icon = (
      <SvgIcon
        className="mr-3 text-gray-400 w-7 h-7 -my-2 flex-shrink-0"
        identifier={iconIdentifier}
      />
    );
  }

  return (
    <CardStyleContext.Consumer>
      {(value) => (
        <div className="px-6 md:px-8">
          <div className={`${className} border-b flex items-center ${value === 'primary' ? 'border-primary' : 'border-gray-150'} py-5 md:py-6`}>
            {icon}
            {children}
          </div>
        </div>
      )}
    </CardStyleContext.Consumer>
  );
}

CardHead.defaultProps = {
  className: '',
  iconIdentifier: null,
};

CardHead.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  iconIdentifier: PropTypes.string,
  className: PropTypes.string,
};
