import React from 'react';
import PropTypes from 'prop-types';
import TableBody from '../TableBody';
import TablePagination from '../TablePagination';
import TableLoadingRow from '../TableLoadingRow';
import TableEmptyStateRow from '../TableEmptyStateRow';

export default function Table({
  children,
  responsive,
  collection,
  rowRenderer,
  paginated,
  tabbed,
  loadingIndicator,
  emptyState,
  onPageChange,
  onPageLengthChange,
  pageLengths,
  isLoading,
}) {
  let tableWrapperClass = 'max-w-full';

  if (responsive) {
    tableWrapperClass += ' overflow-x-auto lg:overflow-x-visible';
  }

  if (!paginated || tabbed) {
    tableWrapperClass += ' shadow-md';
  }

  let table = null;

  if (!isLoading && collection && collection.data && collection.data.length) {
    const rows = collection.data.map(rowRenderer);

    table = (
      <table className={`bg-white w-full text-base ${paginated ? 'rounded-t-xl' : 'rounded-xl'} ${tabbed ? 'rounded-tl-none' : ''}`}>
        {children}

        <TableBody>
          {rows}
        </TableBody>
      </table>
    );
  } else if (isLoading) {
    // Loading indicator...
    table = loadingIndicator || <TableLoadingRow />;
  } else {
    // Empty state...
    table = emptyState || <TableEmptyStateRow className={`${paginated ? 'rounded-t-xl' : 'rounded-xl'}`} />;
  }

  const pagination = paginated
    ? (
      <TablePagination
        className="shadow-md rounded-b-xl"
        collection={collection}
        onPageChange={onPageChange}
        onPageLengthChange={onPageLengthChange}
        pageLengths={pageLengths}
        disabled={isLoading}
      />
    )
    : null;

  return (
    <div>
      <div className={tableWrapperClass}>
        {table}
      </div>

      {pagination}
    </div>
  );
}

Table.defaultProps = {
  responsive: false,
  paginated: false,
  tabbed: false,
  isLoading: false,
  loadingIndicator: null,
  emptyState: null,
  collection: null,
  onPageChange: () => {},
  onPageLengthChange: null,
  pageLengths: [25, 50, 100, 250],
};

Table.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  responsive: PropTypes.bool,
  paginated: PropTypes.bool,
  tabbed: PropTypes.bool,
  collection: PropTypes.shape({
    total: PropTypes.number,
    current_page: PropTypes.number,
    per_page: PropTypes.number,
    last_page: PropTypes.number,
    /* eslint-disable react/forbid-prop-types */
    data: PropTypes.arrayOf(PropTypes.object),
  }),
  rowRenderer: PropTypes.func.isRequired,
  loadingIndicator: PropTypes.node,
  emptyState: PropTypes.node,
  isLoading: PropTypes.bool,
  onPageChange: PropTypes.func,
  onPageLengthChange: PropTypes.func,
  pageLengths: PropTypes.arrayOf(PropTypes.number),
};
