import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import PlanBlock from './PlanBlock';
import useSubscriptionActions from '../../store/actions/subscription';
import { activeSubscriptionSelector } from '../../store/selectors/auth';
import NoActiveSubscriptionModal from './NoActiveSubscriptionModal';
import Addons from './Addons';
import ConfirmDialog from '../ConfirmDialog';
import { apiLoadingFamily } from '../../store/atoms/api';
import { translationsAtom } from '../../store/atoms/i18n';

export default function Plans({
  plans,
  features,
  showPricesUnit,
}) {
  const t = useRecoilValue(translationsAtom);
  const activeSubscription = useRecoilValue(activeSubscriptionSelector);
  const subscriptionActions = useSubscriptionActions();
  const [addonOrderConfirmDialogVisible, setAddonOrderConfirmDialogVisible] = useState(false);
  const [addonIdToBeOrdered, setAddonIdToBeOrdered] = useState(null);
  const subscriptionLoadingState = useRecoilValue(apiLoadingFamily('subscriptions'));

  const headColumns = useMemo(() => {
    return plans.map((plan, index) => {
      const planFeatures = features[plan.identifier];

      // Don't show the free plan.
      if (plan.identifier === 'free') {
        return null;
      }

      return (
        <React.Fragment key={plan.title}>
          <PlanBlock
            plan={plan}
            showPricesUnit={showPricesUnit}
            iconActive={`truck-level-${(index + 1)}-active.svg`}
            iconInactive={`truck-level-${(index + 1)}-inactive.svg`}
            identifier={plan.identifier}
            features={planFeatures}
          />
        </React.Fragment>
      );
    });
  }, [plans, showPricesUnit, features]);

  const [noActiveSubscriptionModalVisible, setNoActiveSubscriptionModalVisible] = useState(false);

  const onNoActiveSubscriptionModalClose = useCallback(() => {
    setNoActiveSubscriptionModalVisible(false);
  }, []);

  const onOrderAddonClick = useCallback(async (event) => {
    if (!activeSubscription) {
      setNoActiveSubscriptionModalVisible(true);
      return;
    }

    setAddonIdToBeOrdered(event.target.id);

    setAddonOrderConfirmDialogVisible(true);
  }, [activeSubscription]);

  const onOrderAddonConfirm = useCallback(async () => {
    if (!activeSubscription) {
      setNoActiveSubscriptionModalVisible(true);
      return;
    }

    await subscriptionActions.orderAddon(addonIdToBeOrdered);

    setAddonOrderConfirmDialogVisible(false);
  }, [activeSubscription, subscriptionActions, addonIdToBeOrdered]);

  return (
    <>
      <div className="subscription-modal-scroll overflow-x-auto -mt-12">
        <div className="grid subscription-modal-line gap-4 mb-6 md:mb-8">
          {headColumns}
        </div>

        <div className="grid subscription-modal-line gap-4 my-6 md:my-8">
          <div className="col-span-3">
            <h3 className="text-xl font-bold mb-4 text-center">Add-ons</h3>

            <hr />
          </div>
        </div>

        <Addons onOrderAddonClick={onOrderAddonClick} />
      </div>

      <NoActiveSubscriptionModal
        onModalClose={onNoActiveSubscriptionModalClose}
        visible={noActiveSubscriptionModalVisible}
      />

      <ConfirmDialog
        isOpen={addonOrderConfirmDialogVisible}
        onCancel={() => setAddonOrderConfirmDialogVisible(false)}
        description={t.subscription_modal.addon_order_confirm_description}
        title={t.subscription_modal.addon_order_confirm_title}
        onSubmit={onOrderAddonConfirm}
        loadingState={subscriptionLoadingState}
        submitButtonText={t.subscription_modal.addon_order_confirm_button}
        cancelButtonText={t.subscription_modal.addon_order_cancel_button}
      />
    </>
  );
}

Plans.defaultProps = {
  //
};

Plans.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.shape({
    plan: PropTypes.string,
    title: PropTypes.string,
    price_monthly: PropTypes.string,
    price_monthly_subtitle: PropTypes.string,
    price_yearly: PropTypes.string,
    price_yearly_subtitle: PropTypes.string,
  })).isRequired,
  features: PropTypes.shape({
    /* eslint-disable react/forbid-prop-types */
    free: PropTypes.arrayOf(PropTypes.object),
    pro: PropTypes.arrayOf(PropTypes.object),
    'pro-plus': PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  showPricesUnit: PropTypes.oneOf(['monthly', 'yearly']).isRequired,
};
