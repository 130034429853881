import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../components/Table';
import TableHead from '../../components/TableHead';
import TableRow from '../../components/TableRow';

export default function AppointmentTemplatesTable({
  collection,
  rowRenderer,
  isLoading,
  onPageChange,
  hidden,
}) {
  return (
    <div className={hidden ? 'hidden' : ''}>
      <Table
        paginated
        responsive
        collection={collection}
        rowRenderer={rowRenderer}
        isLoading={isLoading}
        onPageChange={onPageChange}
      >
        <TableHead>
          <TableRow>
            <th className="py-5 px-6">Titel</th>
          </TableRow>
        </TableHead>
      </Table>
    </div>
  );
}

AppointmentTemplatesTable.defaultProps = {
  isLoading: false,
  collection: null,
  onPageChange: () => {},
  hidden: false,
};

AppointmentTemplatesTable.propTypes = {
  collection: PropTypes.shape({
    total: PropTypes.number,
    current_page: PropTypes.number,
    per_page: PropTypes.number,
    last_page: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.arrayOf(PropTypes.object),
  }),
  rowRenderer: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onPageChange: PropTypes.func,
  hidden: PropTypes.bool,
};
