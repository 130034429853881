import React from 'react';
import PropTypes from 'prop-types';

export const CardStyleContext = React.createContext('default');

export default function Card({ children, className, styleType }) {
  let cardClasses = `${className} rounded-xl shadow-md overflow-hidden`;

  if (styleType === 'default') {
    cardClasses += ' bg-white';
  } else if (styleType === 'primary') {
    cardClasses += ' bg-primary-darker text-white';
  }

  return (
    <div className={cardClasses}>
      <CardStyleContext.Provider value={styleType}>
        {children}
      </CardStyleContext.Provider>
    </div>
  );
}

Card.defaultProps = {
  styleType: 'default',
  className: '',
};

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  styleType: PropTypes.string,
};
