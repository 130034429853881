import PropTypes from 'prop-types';
import React from 'react';
import TableRow from '../../components/TableRow';

export default function AppointmentTemplateRow({ item, onClick, showStandardTemplate }) {
  return (
    item.default_template
      ? (
        <TableRow key={item.uuid} onClick={onClick}>
          <td
            className={`py-4 px-6 font-bold whitespace-nowrap text-lg ${!showStandardTemplate ? 'hidden' : ''}`}
            data-key={item.id}
            draggable="false"
          >
            {item.title}
          </td>
        </TableRow>
      )
      : (
        <TableRow key={item.uuid} onClick={onClick}>
          <td
            className="py-4 px-6 font-bold whitespace-nowrap text-lg event"
            data-key={item.id}
          >
            {item.title}
          </td>
        </TableRow>
      )
  );
}

AppointmentTemplateRow.defaultProps = {
  onClick: () => {
  },
  showStandardTemplate: true,
};

AppointmentTemplateRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    uuid: PropTypes.string,
    title: PropTypes.string,
    default_template: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func,
  showStandardTemplate: PropTypes.bool,
};
