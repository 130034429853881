import { atom } from 'recoil';

// eslint-disable-next-line import/prefer-default-export

export const appointmentTemplateAtom = atom({
  key: 'appointmentTemplateAtom',
  default: null,
});

export const appointmentTemplatesAtom = atom({
  key: 'appointmentTemplatesAtom',
  default: [],
});

export const appointmentTemplatesTableAtom = atom({
  key: 'appointmentTemplatesTableAtom',
  default: {
    data: [],
  },
});

export const appointmentTemplatesFiltersAtom = atom({
  key: 'appointmentTemplatesFiltersAtom',
  default: {
    search: '',
    page: 1,
  },
});
