import { useRecoilState, useSetRecoilState } from 'recoil';
import useApi from '../../hooks/useApi';
import { apiLoadingFamily } from '../atoms/api';
import {
  appointmentTemplateAtom,
  appointmentTemplatesAtom,
  appointmentTemplatesFiltersAtom,
  appointmentTemplatesTableAtom,
} from '../atoms/appointmentTemplates';
import useToastActions from './toasts';

export default function useAppointmentTemplateActions() {
  const setAppointmentTemplates = useSetRecoilState(appointmentTemplatesAtom);
  const setAppointmentTemplatesTable = useSetRecoilState(appointmentTemplatesTableAtom);
  const setAppointmentTemplate = useSetRecoilState(appointmentTemplateAtom);
  const setAppointmentTemplatesState = useSetRecoilState(
    apiLoadingFamily('appointment-templates-overview'),
  );

  const toastActions = useToastActions();

  const [
    appointmentTemplatesFilters,
    setAppointmentTemplatesFilters,
  ] = useRecoilState(appointmentTemplatesFiltersAtom);

  const { handle: appointmentTemplatesApiHandle } = useApi({
    onLoadingStateChange: (loadingState) => setAppointmentTemplatesState(loadingState),
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  async function fetchAll() {
    const results = await appointmentTemplatesApiHandle('get', 'appointment-templates');

    if (results) {
      setAppointmentTemplates(results);
    }

    return results;
  }

  async function fetchAllFiltered() {
    const results = await appointmentTemplatesApiHandle('get', 'appointment-templates', appointmentTemplatesFilters);

    if (results) {
      setAppointmentTemplatesTable(results);
    }

    return results;
  }

  async function fetch(id) {
    const result = await appointmentTemplatesApiHandle('get', `appointment-templates/${id}`);

    if (result) {
      setAppointmentTemplate(result);
    }

    return result;
  }

  async function store(data) {
    const result = await appointmentTemplatesApiHandle('post', 'appointment-templates', data);

    return result;
  }

  async function update(id, data) {
    const result = await appointmentTemplatesApiHandle('put', `appointment-templates/${id}`, data);

    return result;
  }

  async function destroy(id) {
    const result = await appointmentTemplatesApiHandle('delete', `appointment-templates/${id}`);

    return result;
  }

  function updateFilter(key, value) {
    setAppointmentTemplatesFilters({
      ...appointmentTemplatesFilters,
      [key]: value,
      page: key === 'page' ? value : 1,
    });
  }

  return {
    fetchAll,
    fetchAllFiltered,
    store,
    fetch,
    update,
    destroy,
    updateFilter,
  };
}
