import * as yup from 'yup';

export default function useAppointmentSettingsSchema() {
  const schema = {
    minimal_delivery_days: yup
      .number()
      .nullable()
      .transform((curr, orig) => {
        return orig === '' ? null : curr;
      }),
    available_delivery_days: yup
      .number()
      .nullable()
      .transform((curr, orig) => {
        return orig === '' ? null : curr;
      }),
    default_delivery_day: yup
      .number()
      .nullable()
      .min(0)
      .max(7),
  };

  return {
    schema,
    cyclicDependencies: [],
  };
}
