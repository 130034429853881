import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { userAtom } from '../../store/atoms/auth';
import useEcho from '../../hooks/useEcho';
import useBackgroundProcessActions from '../../store/actions/backgroundProcess';
import JobModal from './jobModal';
import useJobModalActions from '../../store/actions/jobModal';
import { jobToastMessagesAtom } from '../../store/atoms/jobToasts';
import useScheduleDetailActions from '../../store/actions/scheduleDetail';
import { scheduleDetailIsCalculating } from '../../store/atoms/scheduleDetail';
import JobMessage from './jobMessage';
import { companySelector } from '../../store/selectors/auth';
import useRegisterStepTasksActions from '../../store/actions/registerStepTasks';
import { importSuccessAtom } from '../../store/atoms/registerStepTasks';
import { registerModalRouteDoneAtom } from '../../store/atoms/registerModal';

export default function JobToasts() {
  const user = useRecoilValue(userAtom);
  const company = useRecoilValue(companySelector);
  const [messages, setMessages] = useRecoilState(jobToastMessagesAtom);
  const { isConnected } = useEcho();
  const backgroundProcessActions = useBackgroundProcessActions();
  const jobModalActions = useJobModalActions();
  const scheduleDetailActions = useScheduleDetailActions();
  const setScheduleDetailIsCalculating = useSetRecoilState(scheduleDetailIsCalculating);
  const registerStepTasksActions = useRegisterStepTasksActions();
  const setImportSuccess = useSetRecoilState(importSuccessAtom);
  const setRouteDone = useSetRecoilState(registerModalRouteDoneAtom);

  const removeMessage = (jobId) => {
    setMessages((prevMessages) => ({
      ...prevMessages,
      [jobId]: {
        ...prevMessages[jobId],
        isVisible: false,
      },
    }));

    setTimeout(() => {
      setMessages((prevMessages) => {
        const updatedMessages = { ...prevMessages };
        delete updatedMessages[jobId];
        return updatedMessages;
      });
    }, 500);
  };

  const mute = (jobId, id) => {
    backgroundProcessActions.mute({
      id,
    }).then(() => {
      removeMessage(jobId);
    });
  };

  const removeCallback = useCallback((jobId, id) => {
    mute(jobId, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleCallback = useCallback((jobId) => {
    setMessages((prevMessages) => ({
      ...prevMessages,
      [jobId]: {
        ...prevMessages[jobId],
        isToggled: !prevMessages[jobId].isToggled,
      },
    }));
  }, [setMessages]);

  const openJobModalCallback = useCallback((jobId) => {
    jobModalActions.toggleModal(jobId, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobModalActions]);

  const formattedMessages = useMemo(() => {
    return Object.keys(messages).map((jobId, index) => (
      <JobMessage
        key={jobId}
        jobId={jobId}
        message={messages[jobId]}
        toggleCallback={toggleCallback}
        removeCallback={removeCallback}
        openJobModalCallback={openJobModalCallback}
        index={index}
      />
    ));
  }, [messages, removeCallback, toggleCallback, openJobModalCallback]);

  const handleActions = useCallback((data) => {
    const {
      action,
    } = data;

    if (!action) {
      return;
    }

    const actions = {
      getSchedule: (uuid) => {
        if (window.location.pathname !== `/schedules/${uuid}`) {
          return;
        }

        scheduleDetailActions.fetch(uuid);
        setScheduleDetailIsCalculating(false);
        setRouteDone(true);
      },
      lockSchedule: (uuid) => {
        if (window.location.pathname !== `/schedules/${uuid}`) {
          return;
        }

        setScheduleDetailIsCalculating(true);
      },
      mute: (jobId) => {
        removeMessage(jobId);
      },
      importComplete: () => {
        if (window.location.pathname !== '/register/step-4-tasks') {
          return;
        }

        setImportSuccess(true);
        registerStepTasksActions.onNextStep([]);
      },
    };

    const func = actions[action.function];

    if (typeof func === 'function') {
      func(...action.parameters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleDetailActions, setScheduleDetailIsCalculating]);

  const updateMessages = useCallback((data) => {
    const {
      id,
      job_id: jobId,
      force_toggle: forceToggle,
      data: detailData,
      state: processState,
      now,
    } = data;

    const {
      title: identifier,
      type,
      state,
      small_state: smallState,
      progress,
      started_at: startedAt,
      completed_at: completedAt,
    } = detailData;

    setMessages((prevMessages) => {
      const existingMessage = prevMessages[jobId];

      if (!existingMessage) {
        setTimeout(() => {
          setMessages((prevMessages2) => ({
            ...prevMessages2,
            [jobId]: {
              ...prevMessages2[jobId],
              isVisible: true,
            },
          }));
        }, 100);
      }

      if (existingMessage && completedAt && !existingMessage.completedAt) {
        setTimeout(() => {
          setMessages((prevMessages2) => {
            const existingMessage2 = prevMessages2[jobId];

            if (!existingMessage2 || !existingMessage2.completedAt) {
              return prevMessages2;
            }

            return {
              ...prevMessages2,
              [jobId]: {
                ...prevMessages2[jobId],
                isToggled: true,
              },
            };
          });
        }, 3000);
      }

      return {
        ...prevMessages,
        [jobId]: {
          id,
          identifier,
          isVisible: existingMessage?.isVisible || false,
          isToggled: (forceToggle !== null) ? forceToggle : existingMessage?.isToggled || false,
          progress,
          type,
          state,
          smallState,
          processState,
          startedAt,
          completedAt,
          diff: now - Date.now(),
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMessages]);

  useEffect(() => {
    let channelUser;
    let channelCompany;

    if (isConnected && user) {
      backgroundProcessActions.fetch().then((results) => {
        results.forEach((result) => {
          updateMessages(result);
        });
      });
    }

    if (user && window.Echo && isConnected) {
      channelUser = window.Echo.private(`jobs.user.${user.id}`);

      channelUser.listen('.jobs.broadcast_job', (data) => {
        updateMessages(data);
        handleActions(data);

        if (data.hide) {
          setTimeout(() => {
            mute(data.jobId, data.id);
          }, (data.hide * 1000));
        }
      });
    }

    if (company && window.Echo && isConnected) {
      channelCompany = window.Echo.private(`jobs.company.${company.id}`);

      channelCompany.listen('.jobs.broadcast_job', (data) => {
        updateMessages(data);
        handleActions(data);

        if (data.hide) {
          setTimeout(() => {
            mute(data.jobId, data.id);
          }, (data.hide * 1000));
        }
      });
    }

    return () => {
      if (channelUser) {
        channelUser.stopListening('.jobs.broadcast_job');
      }

      if (channelCompany) {
        channelCompany.stopListening('.jobs.broadcast_job');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, company?.id, isConnected]);

  if (!user) {
    return null;
  }

  return (
    <>
      <div
        className="fixed z-[15000] top-0 right-0 text-3xl flex flex-col m-3 mr-0"
      >
        {formattedMessages}
      </div>

      <JobModal />
    </>
  );
}

JobToasts.defaultProps = {
  //
};

JobToasts.propTypes = {
  //
};
