/* eslint-disable react/jsx-props-no-spreading */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from './ErrorMessage';
import ConnectForm from './ConnectForm';
import HelpLabel from './HelpLabel';
import countries from '../../assets/countries.json';

export default function PhoneNumberInput({
  label,
  labelHelp,
  dangerousLabelHelp,
  helpPanelClassName,
  id,
  schema,
  inputType,
  readOnly,
  onKeyUp,
  onInput,
  autoFocus,
  step,
  autoComplete,
  required,
  placeholder,
}) {
  const isRequired = schema.exclusiveTests?.required || required;

  const requiredLabelNode = isRequired
    ? <abbr className="text-red-600 ml-1" title="Required">*</abbr>
    : null;

  const options = useMemo(() => {
    return countries.map((item) => (
      <option value={item.calling_code} key={item.short_name} selected={item.short_name === 'NL' ? 'selected' : ''}>
        {`${item.name} +${item.calling_code}`}
      </option>
    ));
  }, []);

  return (
    <ConnectForm name={id}>
      {({
        register,
        setValue,
        watch,
        error,
      }) => {
        const selectId = `select-${id}`;
        const inputId = `input-${id}`;

        const handleCombinedChange = () => {
          const selectValue = watch(selectId);
          const inputValue = watch(inputId);
          setValue(id, `+${selectValue} ${inputValue}`);
        };

        return (
          <>
            <label htmlFor={id}>
              <div className="block mb-4 font-bold">
                {label}

                {requiredLabelNode}

                <HelpLabel
                  text={labelHelp}
                  dangerousLabelHelp={dangerousLabelHelp}
                  panelClassName={helpPanelClassName}
                />
              </div>

              <div className="grid grid-cols-12 gap-2">
                <select
                  className={`form-control col-span-3 ${error ? 'form-control--error' : ''}`}
                  name={selectId}
                  id={selectId}
                  {...register(selectId, {
                    onChange: handleCombinedChange,
                  })}
                >
                  {options}
                </select>

                <input
                  className={`form-control col-span-9 ${error ? 'form-control--error' : ''}`}
                  name={inputId}
                  id={inputId}
                  type={inputType}
                  readOnly={readOnly}
                  onKeyUp={onKeyUp}
                  onInput={onInput}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={autoFocus}
                  step={step}
                  {...register(inputId, {
                    onChange: handleCombinedChange,
                  })}
                  autoComplete={autoComplete}
                  placeholder={placeholder}
                />
              </div>

            </label>
            <ErrorMessage message={error} />
          </>
        );
      }}
    </ConnectForm>
  );
}

PhoneNumberInput.defaultProps = {
  schema: {},
  inputType: 'text',
  labelHelp: null,
  readOnly: false,
  onKeyUp: null,
  onInput: null,
  autoFocus: false,
  step: null,
  autoComplete: 'on',
  required: false,
  placeholder: '',
  dangerousLabelHelp: false,
  helpPanelClassName: '',
};

PhoneNumberInput.propTypes = {
  label: PropTypes.string.isRequired,
  labelHelp: PropTypes.string,
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object,
  inputType: PropTypes.string,
  readOnly: PropTypes.bool,
  onKeyUp: PropTypes.func,
  onInput: PropTypes.func,
  autoFocus: PropTypes.bool,
  step: PropTypes.string,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  dangerousLabelHelp: PropTypes.bool,
  helpPanelClassName: PropTypes.string,
};
