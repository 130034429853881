import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { translationsAtom } from '../../store/atoms/i18n';
import ConfirmDialog from '../../components/ConfirmDialog';
import { apiErrorsFamily, apiLoadingFamily } from '../../store/atoms/api';
import useAppointmentTemplateActions from '../../store/actions/appointmentTemplates';

export default function AppointmentTemplateDeleteConfirm({
  appointmentTemplateId,
  isOpen,
  onClose,
  onDeleted,
  redirectToOverview,
}) {
  const t = useRecoilValue(translationsAtom);
  const appointmentTemplateActions = useAppointmentTemplateActions();
  const navigate = useNavigate();
  const vehicleDeleteErrors = useRecoilValue(apiErrorsFamily('vehicle-delete'));
  const vehicleDeleteLoadingState = useRecoilValue(apiLoadingFamily('vehicle-delete'));

  const onDeleteConfirm = useCallback(() => {
    // Call the API to delete the task
    appointmentTemplateActions.destroy(appointmentTemplateId)
      .then(() => {
        // Call on close to hide the dialog.
        onClose();

        // Optional callback to handle more logic when the item has been deleted.
        onDeleted();

        // Optional redirect back to the overview.
        if (redirectToOverview) {
          navigate('/appointment-calendar');
        }
      });
  }, [
    onClose,
    onDeleted,
    appointmentTemplateId,
    appointmentTemplateActions,
    redirectToOverview,
    navigate,
  ]);

  return (
    <ConfirmDialog
      isOpen={isOpen}
      title={t.appointment_template.confirm_delete_template_title}
      description={t.appointment_template.confirm_delete_template_description}
      submitButtonText={t.appointment_template.confirm_delete_submit}
      errors={vehicleDeleteErrors}
      loadingState={vehicleDeleteLoadingState}
      onSubmit={onDeleteConfirm}
      onCancel={onClose}
    />
  );
}

AppointmentTemplateDeleteConfirm.defaultProps = {
  redirectToOverview: false,
  onDeleted: () => {},
};

AppointmentTemplateDeleteConfirm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  appointmentTemplateId: PropTypes.number.isRequired,
  redirectToOverview: PropTypes.bool,
  onDeleted: PropTypes.func,
};
