/* eslint-disable react/jsx-props-no-spreading */

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useRecoilValue } from 'recoil';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { zipObject } from 'lodash';
import useToastActions from '../../store/actions/toasts';
import { toastTypes } from '../../store/atoms/toasts';
import { translationsAtom } from '../../store/atoms/i18n';

function Form({
  className,
  children,
  schemaObject,
  schemaCyclicDependencies,
  onSubmit,
  onWatchChange,
  watches,
  defaultValues,
  forwardedRef,
}) {
  const t = useRecoilValue(translationsAtom);
  const schema = yup.object().shape(schemaObject, schemaCyclicDependencies);
  const [hasShownErrorMessage, setHasShownErrorMessage] = useState(false);
  const toastActions = useToastActions();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const formWatcher = useWatch({
    name: watches,
    control: methods.control,
  });

  useEffect(() => {
    if (watches.length) {
      onWatchChange(zipObject(watches, formWatcher));
    }
  }, [formWatcher, watches, onWatchChange]);

  useImperativeHandle(forwardedRef, () => {
    return {
      resetForm: methods.reset,
      getValues: methods.getValues,
    };
  });

  if (Object.keys(methods.formState.errors).length) {
    if (!hasShownErrorMessage) {
      toastActions.addGlobalToastItem(t.validation.general_form_error, toastTypes.ERROR);

      setHasShownErrorMessage(true);
    }

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('Form errors', methods.formState.errors);
    }
  }

  return (
    <FormProvider {...methods}>
      <form
        className={className}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        {children}
      </form>
    </FormProvider>
  );
}

Form.defaultProps = {
  onSubmit: () => {},
  schemaCyclicDependencies: [],
  defaultValues: {},
  watches: [],
  onWatchChange: () => {},
  forwardedRef: null,
  className: '',
};

Form.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schemaObject: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schemaCyclicDependencies: PropTypes.arrayOf(PropTypes.array),
  onSubmit: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValues: PropTypes.object,
  watches: PropTypes.arrayOf(PropTypes.string),
  onWatchChange: PropTypes.func,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.shape({
        resetForm: PropTypes.func,
      }),
    }),
  ]),
};

export default forwardRef((props, ref) => (
  <Form {...props} forwardedRef={ref} />
));
