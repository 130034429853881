import { atom } from 'recoil';

// eslint-disable-next-line import/prefer-default-export
export const appointmentTemplateDaysAtom = atom({
  key: 'appointmentTemplateDaysAtom',
  default: [],
});

export const appointmentTemplateWeekDaysAtom = atom({
  key: 'appointmentTemplateWeekDaysAtom',
  default: [],
});
