import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { useDebouncedEffect } from '@react-hookz/web';
import AppointmentTemplatesTable from '../AppointmentTemplate/AppointmentTemplatesTable';
import AppointmentTemplateRow from '../AppointmentTemplate/AppointmentTemplateRow';
import { apiLoadingFamily } from '../../store/atoms/api';
import { appointmentTemplatesTableAtom, appointmentTemplatesFiltersAtom } from '../../store/atoms/appointmentTemplates';
import useAppointmentTemplateActions from '../../store/actions/appointmentTemplates';
import OverviewHead from '../../components/OverviewHead';
import Button from '../../components/Button';
import { translationsAtom } from '../../store/atoms/i18n';

export default function Templates() {
  const t = useRecoilValue(translationsAtom);
  const appointmentTemplatesTable = useRecoilValue(appointmentTemplatesTableAtom);
  const appointmentTemplatesFilters = useRecoilValue(appointmentTemplatesFiltersAtom);
  const navigate = useNavigate();
  const appointmentTemplatesState = useRecoilValue(apiLoadingFamily('appointment-templates-overview'));
  const appointmentTemplatesActions = useAppointmentTemplateActions();

  useDebouncedEffect(() => {
    appointmentTemplatesActions.fetchAllFiltered();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentTemplatesFilters], 500);

  const onPageChange = useCallback((pageNumber) => {
    appointmentTemplatesActions.updateFilter('page', pageNumber);
  }, [appointmentTemplatesActions]);

  const onSearchChange = useCallback(({ currentTarget }) => {
    appointmentTemplatesActions.updateFilter('search', currentTarget.value);
  }, [appointmentTemplatesActions]);

  const onRowClick = useCallback((row) => {
    navigate(`/appointment-template/${row.id}`);
  }, [navigate]);

  const onCreate = useCallback(() => {
    navigate('/appointment-template/create');
  }, [navigate]);

  const rowRenderer = (row) => (
    <AppointmentTemplateRow
      key={row.id}
      item={row}
      onClick={() => onRowClick(row)}
    />
  );

  return (
    <div className="bg-white rounded-b-xl md:rounded-xl shadow-md p-6 md:p-8">
      <OverviewHead
        title={t.appointment.templates.title}
        filters={(
          <input
            type="search"
            aria-label={t.appointment.templates.search}
            placeholder={t.appointment.templates.search}
            className="form-control form-control--shadow w-72"
            value={appointmentTemplatesFilters.search}
            onChange={onSearchChange}
          />
        )}
        actions={(
          <div>
            <Button
              styleType="primary"
              icon="plus"
              iconClass="mr-2 opacity-60"
              onClick={onCreate}
            >
              {t.appointment.templates.new_template}
            </Button>
          </div>
        )}
      />

      <AppointmentTemplatesTable
        rowRenderer={rowRenderer}
        collection={appointmentTemplatesTable}
        isLoading={appointmentTemplatesState === 'loading'}
        onPageChange={onPageChange}
      />
    </div>
  );
}
