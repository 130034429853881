import React, { useState } from 'react';
import { useDebouncedEffect } from '@react-hookz/web';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getUnixTime, parseISO } from 'date-fns';
import { userAtom } from '../../store/atoms/auth';
import { activeSubscriptionSelector, subscriptionPlanSelector } from '../../store/selectors/auth';
import useWindowSize from '../../hooks/useWindowSize';
import SvgIcon from '../SvgIcon';
import { intercomBootAtom } from '../../store/atoms/intercom';

const baseIntercomSettings = {
  api_base: 'https://api-iam.intercom.io',
  app_id: 'vnfjbjyu',
};

export default function Intercom() {
  const user = useRecoilValue(userAtom);
  const subscriptionPlan = useRecoilValue(subscriptionPlanSelector);
  const activeSubscription = useRecoilValue(activeSubscriptionSelector);
  const { width } = useWindowSize();
  const [intercomInitiated, setIntercomInitiated] = useState(false);
  const [intercomBoot, setIntercomBoot] = useRecoilState(intercomBootAtom);

  const handleCloseIntercom = () => {
    setIntercomBoot(false);
  };

  useDebouncedEffect(() => {
    if (intercomInitiated && !intercomBoot) {
      setIntercomInitiated(false);
      window.Intercom('shutdown');
    }

    if (!width || !user || width <= 480) {
      return;
    }

    if (!intercomInitiated && intercomBoot) {
      const intercomSettings = {
        ...baseIntercomSettings,
        name: user.name,
        email: user.email,
        user_hash: user.email_hashed,
        company: {
          id: user.company.id,
          name: user.company.name,
          plan: activeSubscription && subscriptionPlan ? subscriptionPlan.name : 'Free',
          'Subscription Status': activeSubscription ? activeSubscription.status : 'inactive',
          'Vehicle Count': activeSubscription ? activeSubscription.quantity : 0,
          'Subscription Term': user.company.term ? user.company.term : null,
        },
        created_at: getUnixTime(parseISO(user.created_at)),
      };

      setIntercomInitiated(true);
      window.Intercom('boot', intercomSettings);
      window.Intercom('show');
    }
  }, [intercomBoot, width], 400);

  return (
    intercomInitiated && (
      <div className="relative z-[2147483600]">
        <button
          type="button"
          onClick={handleCloseIntercom}
          className="fixed bottom-18 right-18 bg-red-600 text-white rounded-full px-2 py-1.5 cursor-pointer"
        >
          <SvgIcon
            identifier="cross"
            className="w-3"
          />
        </button>
      </div>
    )
  );
}
