import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import SvgIcon from '../../components/SvgIcon';
import { translationsAtom } from '../../store/atoms/i18n';

export default function DayCellContent({
  date,
  appointmentTemplateDay,
  appointmentTemplateWeekDay,
  appointmentTemplates,
  today,
  appointmentTemplateDaysActions,
}) {
  const t = useRecoilValue(translationsAtom);

  const [currentTemplateDay, setCurrentTemplateDay] = useState();
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [templateType, setTemplateType] = useState('standard');
  const disabled = date < today;

  const onSubmit = useCallback(async (templateId) => {
    await appointmentTemplateDaysActions.store(templateId, date.toISOString().split('T')[0], setCurrentTemplateDay);
  }, [appointmentTemplateDaysActions, date]);

  const handleDelete = useCallback(async () => {
    await appointmentTemplateDaysActions.destroy(date.toISOString().split('T')[0], setCurrentTemplateDay);
  }, [appointmentTemplateDaysActions, date]);

  useEffect(() => {
    setCurrentTemplateDay(appointmentTemplateDay);
  }, [appointmentTemplateDay]);

  useEffect(() => {
    const templateDayId = currentTemplateDay?.appointment_template_id;
    const templateWeekDayId = appointmentTemplateWeekDay?.appointment_template_id;

    if (templateDayId) {
      setSelectedTemplateId(templateDayId);
      setTemplateType('day');
    } else if (templateWeekDayId) {
      setSelectedTemplateId(templateWeekDayId);
      setTemplateType('weekday');
    } else {
      setSelectedTemplateId(null);
      setTemplateType('standard');
    }
  }, [currentTemplateDay, appointmentTemplateWeekDay]);

  const handleDropdownChange = (e) => {
    const newTemplateId = parseInt(e.target.value, 10);
    setSelectedTemplateId(newTemplateId);

    onSubmit(newTemplateId);
  };

  const getTemplateClass = () => {
    if (disabled) return 'disabled bg-gray-200 appearance-none';
    if (templateType === 'day') return 'bg-primary text-white border-none';
    if (templateType === 'weekday') return 'bg-white text-black border border-primary';
    return 'bg-white text-black border border-gray-300';
  };

  return (
    <div>
      <div className="mb-5 pl-1 pr-2 flex justify-between gap-x-2 items-center">
        <span>{date.getDate()}</span>

        {currentTemplateDay && !disabled ? (
          <button
            onClick={handleDelete} // Add your delete handler here
            className="block p-0 rounded-lg text-left text-gray-500 hover:text-red-600"
            aria-label={t.appointment.calendar.restore_template}
            title={t.appointment.calendar.restore_template}
            type="button"
          >
            <SvgIcon
              identifier="cross"
            />
          </button>
        ) : (
          ''
        )}
      </div>

      <select
        className={`form-control form-control--sm ${getTemplateClass()}`}
        onChange={handleDropdownChange}
        disabled={disabled}
        aria-disabled={disabled}
        value={selectedTemplateId || ''}
      >
        {appointmentTemplates.map((template) => (
          <option key={template.id} value={template.id}>
            {template.title}
          </option>
        ))}
      </select>
    </div>
  );
}

DayCellContent.defaultProps = {
  defaultTemplate: null,
  appointmentTemplateDay: null,
  appointmentTemplateWeekDay: null,
};

DayCellContent.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  appointmentTemplateDay: PropTypes.shape({
    appointment_template_id: PropTypes.number,
    start: PropTypes.string,
  }),
  appointmentTemplateWeekDay: PropTypes.shape({
    appointment_template_id: PropTypes.number,
  }),
  appointmentTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  today: PropTypes.number.isRequired,
  appointmentTemplateDaysActions: PropTypes.shape({
    store: PropTypes.func.isRequired,
    destroy: PropTypes.func.isRequired,
  }).isRequired,
  defaultTemplate: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};
