/* eslint-disable react/jsx-props-no-spreading */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactTagsInput from 'react-tagsinput';
import { useFormContext, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import ErrorMessage from './ErrorMessage';
import ConnectForm from './ConnectForm';
import SvgIcon from '../SvgIcon';
import HelpLabel from './HelpLabel';

import 'react-tagsinput/react-tagsinput.css';
import { translationsAtom } from '../../store/atoms/i18n';

export default function TagsInput({
  label,
  labelHelp,
  id,
  name,
  schema,
  className,
}) {
  const t = useRecoilValue(translationsAtom);
  const methods = useFormContext();
  const isRequired = schema.exclusiveTests?.required || false;

  useWatch({
    name: id,
    control: methods.control,
  });

  const requiredLabel = isRequired
    ? <abbr title="Required">*</abbr>
    : null;

  const fieldName = name || id;

  const renderTag = useCallback((tagProps) => {
    const {
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = tagProps;

    return (
      <span key={key} {...other}>
        {getTagDisplayValue(tag)}
        {
          !disabled
            ? (
              <button type="button" className={classNameRemove} onClick={() => onRemove(key)}>
                <SvgIcon identifier="cross" className="w-3 h-3" />
                <span className="sr-only">Verwijderen</span>
              </button>
            )
            : null
        }
      </span>
    );
  }, []);

  return (
    <ConnectForm>
      {({ error, setValue, getValues }) => {
        let value = getValues(id);

        if (!Array.isArray(value)) {
          value = [];
        }

        return (
          <>
            <label
              htmlFor={id}
              className={`${className} font-bold mb-4 block`}
            >
              {label}
              {requiredLabel}
              <HelpLabel text={labelHelp} />
            </label>

            <ReactTagsInput
              name={fieldName}
              id={id}
              value={value}
              className="form-control h-auto min-h-[4rem] px-2 pt-2 pb-0"
              onlyUnique
              tagProps={{
                className: 'bg-gray-200 inline-flex items-center rounded-full mr-2 mb-2 py-3 px-6 leading-tight text-gray-600',
                classNameRemove: 'bg-gray-300 hover:bg-red-700 focus:bg-red-700 hover:text-white focus:text-white flex items-center justify-center w-8 h-8 rounded-full ml-3 -my-2 -mr-4 transition cursor-pointer',
              }}
              inputProps={{
                className: 'leading-tight py-3 px-2 focus:border-0 focus:outline-none min-w-64',
                placeholder: t.task.tags_placeholder,
              }}
              addKeys={[
                9, // Tab
                13, // Enter
                186, // ;
                188, // ,
              ]}
              renderTag={renderTag}
              onChange={(val) => {
                setValue(id, val);
              }}
            />

            <ErrorMessage message={error} />
          </>
        );
      }}
    </ConnectForm>
  );
}

TagsInput.defaultProps = {
  schema: {},
  name: null,
  className: '',
  labelHelp: null,
};

TagsInput.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  labelHelp: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object,
  className: PropTypes.string,
};
