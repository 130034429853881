import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../components/SvgIcon';

export default function TabListItem({
  active,
  title,
  identifier,
  icon,
  onClick,
  tabMode,
}) {
  return (
    <li>
      <button
        type="button"
        className={`
          flex items-center py-4 px-6 rounded-t-xl whitespace-nowrap focus:outline-none
          ${
          active
            ? 'text-primary bg-white font-bold'
            : 'bg-primary-background-dark hover:text-gray-600 focus:text-gray-600 transition'
        }
          ${
          tabMode
            ? ''
            : 'md:px-0 md:w-full md:bg-transparent'
        }
          ${
          !tabMode && !active
            ? 'md:text-gray-400'
            : ''
        }
        `}
        onClick={() => onClick(identifier)}
      >
        <SvgIcon
          identifier={icon}
          className="w-6 h-6 mr-4 flex-shrink-0"
        />
        {title}
      </button>
    </li>
  );
}

TabListItem.defaultProps = {
  tabMode: false,
};

TabListItem.propTypes = {
  active: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  tabMode: PropTypes.bool,
};
