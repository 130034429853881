import React, { useCallback, useEffect, useRef } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Main from '../../components/Main';
import Container from '../../components/Container';
import MetaHead from '../../components/MetaHead';
import SvgIcon from '../../components/SvgIcon';
import Card from '../../components/Card';
import CardHead from '../../components/CardHead';
import CardBody from '../../components/CardBody';
import AppointmentTemplateForm from './AppointmentTemplateForm';
import useAppointmentTemplateSchema from '../../schemas/appointmentTemplateSchema';
import useAppointmentTemplateActions from '../../store/actions/appointmentTemplates';
import { apiErrorsFamily, apiLoadingFamily } from '../../store/atoms/api';
import useToastActions from '../../store/actions/toasts';
import { appointmentTemplateAtom } from '../../store/atoms/appointmentTemplates';
import analytics from '../../helpers/analytics';
import useAuthActions from '../../store/actions/auth';
import { translationsAtom } from '../../store/atoms/i18n';

export default function AppointmentTemplateEdit() {
  const t = useRecoilValue(translationsAtom);
  const formRef = useRef();
  const params = useParams();
  const [schema, schemaCyclicDependencies] = useAppointmentTemplateSchema();
  const appointmentTemplateActions = useAppointmentTemplateActions();
  const authActions = useAuthActions();
  const appointmentTemplate = useRecoilValue(appointmentTemplateAtom);
  const appointmentTemplateDetailValidationErrors = useRecoilValue(apiErrorsFamily('appointment-template'));
  const appointmentTemplateDetailLoadingState = useRecoilValue(apiLoadingFamily('appointment-template'));
  const toastActions = useToastActions();

  const { appointmentTemplateId } = params;

  useEffect(() => {
    appointmentTemplateActions.fetch(appointmentTemplateId)
      .then((data) => {
        formRef.current.resetForm(data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentTemplateId]);

  const onSubmit = useCallback(async (data) => {
    await appointmentTemplateActions.update(appointmentTemplateId, data);

    toastActions.addGlobalToastItem(t.appointment_template.template_updated);

    authActions.fetchUser();

    analytics.track('Appointment template update', {
      category: 'Appointment template update',
    });
    // eslint-disable-next-line max-len
  }, [appointmentTemplateActions, appointmentTemplateId, toastActions, t, authActions]);

  const initialData = { ...appointmentTemplate };

  return (
    <>
      <MetaHead title="Appointment template aanpassen" />

      <Main>
        <Container>
          <NavLink className="btn btn--empty text-gray-600 mr-4" to="/appointment-calendar">
            <SvgIcon identifier="chevronLeft" className="mr-2 text-gray-300" />
            {t.appointment_template.calendar_return}
          </NavLink>

          <Card className="my-6">
            <CardHead>
              <h1 className="text-2xl font-bold">
                {t.appointment_template.title_edit}
              </h1>
            </CardHead>

            <CardBody>
              <div className="md:px-6">
                <AppointmentTemplateForm
                  formRef={formRef}
                  schema={schema}
                  schemaCyclicDependencies={schemaCyclicDependencies}
                  onSubmit={onSubmit}
                  initialData={initialData}
                  validationErrors={appointmentTemplateDetailValidationErrors}
                  loadingState={appointmentTemplateDetailLoadingState}
                  showDelete={!!appointmentTemplate}
                />
              </div>
            </CardBody>
          </Card>
        </Container>
      </Main>
    </>
  );
}
