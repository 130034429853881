import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import { useRecoilValue } from 'recoil';
import SvgIcon from '../SvgIcon';
import { translationsAtom } from '../../store/atoms/i18n';

export default function Modal({
  isOpen,
  onToggleOpen,
  children,
  title,
  widthClass,
  preventClickClose,
  className,
}) {
  const t = useRecoilValue(translationsAtom);

  return (
    <Transition
      as={Fragment}
      show={isOpen}
    >
      <Dialog
        onClose={() => (preventClickClose ? null : onToggleOpen(false))}
        className={`fixed inset-0 z-30 overflow-y-auto ${className}`}
      >
        <div className="flex items-center justify-center min-h-screen p-4 md:p-10 overflow-hidden">
          <Dialog.Overlay
            className="fixed inset-0 bg-black opacity-30"
          />

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className={`${widthClass} relative bg-white rounded-xl w-full mx-auto z-40 p-6 md:p-10`}>
              <button
                className="bg-primary hover:bg-primary-darker focus:bg-primary-darker transition-colors text-white p-3 md:p-4 absolute top-2 right-2 md:-top-4 md:-right-4 rounded-xl"
                type="button"
                onClick={() => onToggleOpen(false)}
                data-new-account-close
              >
                <span className="sr-only">{t.words.close}</span>
                <SvgIcon className="w-5 h-5" identifier="cross" />
              </button>

              <Dialog.Title className="text-2xl mb-6 md:mb-10 font-bold">
                {title}
              </Dialog.Title>

              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

Modal.defaultProps = {
  widthClass: 'max-w-2xl',
  preventClickClose: false,
  className: '',
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggleOpen: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  widthClass: PropTypes.string,
  preventClickClose: PropTypes.bool,
  className: PropTypes.string,
};
