/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from './ErrorMessage';
import ConnectForm from './ConnectForm';
import HelpLabel from './HelpLabel';

export default function TextInput({
  label,
  labelHelp,
  dangerousLabelHelp,
  helpPanelClassName,
  id,
  schema,
  inputType,
  rows,
  readOnly,
  onKeyUp,
  onInput,
  autoFocus,
  step,
  autoComplete,
  required,
  placeholder,
  className,
}) {
  const isRequired = schema.exclusiveTests?.required || required;

  const requiredLabelNode = isRequired
    ? <abbr className="text-red-600 ml-1" title="Required">*</abbr>
    : null;

  return (
    <ConnectForm name={id}>
      {({ register, error }) => (
        <>
          <label htmlFor={id}>
            <div className="block mb-4 font-bold">
              {label}

              {requiredLabelNode}

              <HelpLabel
                text={labelHelp}
                dangerousLabelHelp={dangerousLabelHelp}
                panelClassName={helpPanelClassName}
              />
            </div>
            {rows === 1 ? (
              <input
                className={`form-control ${error ? 'form-control--error' : ''} ${className}`}
                name={id}
                id={id}
                type={inputType}
                readOnly={readOnly}
                onKeyUp={onKeyUp}
                onInput={onInput}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={autoFocus}
                step={step}
                {...register(id)}
                autoComplete={autoComplete}
                placeholder={placeholder}
              />
            ) : (
              <textarea
                className={`form-control ${error ? 'form-control--error' : ''} ${className}`}
                name={id}
                id={id}
                type={inputType}
                rows={rows}
                readOnly={readOnly}
                onKeyUp={onKeyUp}
                onInput={onInput}
                {...register(id)}
                autoComplete={autoComplete}
                placeholder={placeholder}
              />
            )}
          </label>
          <ErrorMessage message={error} />
        </>
      )}
    </ConnectForm>
  );
}

TextInput.defaultProps = {
  schema: {},
  inputType: 'text',
  labelHelp: null,
  rows: 1,
  readOnly: false,
  onKeyUp: null,
  onInput: null,
  autoFocus: false,
  step: null,
  autoComplete: 'on',
  required: false,
  placeholder: '',
  dangerousLabelHelp: false,
  helpPanelClassName: '',
  className: '',
};

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  labelHelp: PropTypes.string,
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object,
  inputType: PropTypes.string,
  rows: PropTypes.number,
  readOnly: PropTypes.bool,
  onKeyUp: PropTypes.func,
  onInput: PropTypes.func,
  autoFocus: PropTypes.bool,
  step: PropTypes.string,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  dangerousLabelHelp: PropTypes.bool,
  helpPanelClassName: PropTypes.string,
  className: PropTypes.string,
};
