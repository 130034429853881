import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

export default function TableRow({ children, onClick, className }) {
  const onRowClick = useCallback((event) => {
    // Prevent the row from opening a page when a specific class is applied.
    // For example if we want to have a clickable button in the row.
    if (
      event.target.closest('.no-row-trigger')
      || (
        // Apparently className can be an object when clicking an SVG
        typeof event.target.className === 'string'
        && event.target.className.indexOf('no-row-trigger') >= 0
      )
    ) {
      return;
    }

    if (onClick) {
      onClick(event);
    }
  }, [onClick]);

  return (
    <tr
      className={`${onClick ? 'hover:bg-primary-background-dark cursor-pointer' : ''} ${className}`}
      onClick={onRowClick}
    >
      {children}
    </tr>
  );
}

TableRow.defaultProps = {
  onClick: null,
  className: '',
};

TableRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
